import React from 'react';
import { Helmet } from 'react-helmet';
import SecondaryHeader from '../../masterLayout/SecondaryHeader';
import { workersCompensation } from './utilities/AccordionData';

// CUSTOME ACCORDION COMPONENT
const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div dangerouslySetInnerHTML={{ __html: title }}></div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && (
        <div
          className="accordion-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      )}
    </div>
  );
};

// BUILD TEMPLATE
const WorkersComp = () => {
  return (
    <React.Fragment>
      <Helmet>
        {' '}
        <title>Workers' Compensation</title>{' '}
      </Helmet>
      <SecondaryHeader page_title="Workers' Compensation" />
      <div className="sectionWrap">
        <div className="sectionContainer" id="cannabis-education">
          <div className="pageContent workers_compensation">
            <div className="introduction">
              <h2>Workers' Compensation</h2>
              {/* <p><strong>&nbsp;</strong></p> */}
              <div className="content">
                <p>
                  <strong>
                    Cannabis Reimbursement Program for Workers’ Compensation
                  </strong>
                </p>
                <p>
                  Workers’ comp benefits can be a life saver if you are injured
                  on the job. It helps to cover lost wages, medical bills, as
                  well as prescription medications. And now, in some states,
                  worker’s compensation programs require medical marijuana to be
                  covered in appropriate cases. That is great news if you can
                  afford to pay out of pocket for the cannabis medicine and wait
                  to be reimbursed.
                </p>
                <p>
                  Bennabis Health has a cannabis reimbursement program in place
                  that helps support access to medical cannabis. Through our
                  program the required cannabis is paid for at no cost to the
                  patient and Bennabis Health is reimbursed from the payor.
                </p>
                <p>
                  If you are interested in finding out about medical marijuana
                  for your workers’ comp treatment, ask your attorney to contact
                  Bennabis Health at memberservices@bennabis.com. We will work
                  with them to get you enrolled so you can start to access
                  medicinal cannabis with no cost to you. As a Workers’ comp
                  patient, you pay nothing to Bennabis Health.
                </p>
                <p>Alternative Treatment for Work-Related Injuries</p>
                <p>
                  Medical Cannabis is known to help treat the side effects and
                  symptoms of many conditions including chronic pain,
                  depression, PTSD, anxiety, migraines, even as a sleep aid. And
                  studies show that when used to address the chronic pain that
                  so often accompanies work-related injuries, medical cannabis
                  gets the worker back on the job sooner.
                </p>
                <p>
                  Bennabis Health recognizes the many challenges individuals
                  face in dealing with the cost of cannabis, as well as the
                  social stigma and fear of discrimination in the workplace. We
                  provide support through our reliable education and expert
                  guidance to help medical cannabis patients navigate these
                  issues.{' '}
                </p>
                <p>&nbsp;</p>
                <h6>Use of Medical Cannabis in Workers’ Comp Can Lead to:</h6>
                <ul style={{ margin: 0 }}>
                  <li>20% improvement in health outcomes.</li>
                  <li>Decline in opioid-related deaths.</li>
                  <li>
                    Reduction in use of prescriptions for depression and pain
                    management.
                  </li>
                  <li>Return to work sooner.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion">
            {workersCompensation.map(({ title, content }, index) => (
              <Accordion title={title} content={content} key={index} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WorkersComp;
