import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'

const Members = () => {
 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>Members</title>{' '}
   </Helmet>
   <SecondaryHeader page_title="Members" />
   <div className="sectionWrap">
    <div className="sectionContainer">
     <div className="member_benefits">
      <div
       className="intro"
       style={{
        fontSize: '16px',
        color: 'rgb(8, 47, 88)',
        fontWeight: 700,
       }}
      >
       <p>
        Do you plan your dispensary run based on when products go on sale?
       </p>
       <p>
        Stop waiting for your favorite products to go on sale. You get our discount no matter when you can get to a Network Dispensary.
       </p>
       <p>
        Join today and save 15% on medical cannabis purchases at Network Dispensaries.
       </p>
      </div>
      <h5
       style={{
        color: '#0A670A'
       }}
      >
       Why Bennabis Health?
      </h5>
      <p>
       Bennabis Health is a healthcare company making medical cannabis more affordable through our network discount.
      </p>
      <p>
       We are a team of patients, advocates, educators, medical and healthcare professionals (we even have a former US Surgeon General on our team!) on the forefront of patient-centric medical cannabis, devoted to improving access and reducing costs of medical cannabis through our employer-sponsored and individual programs.
      </p>
      <h5
       style={{
        color: '#0A670A'
       }}
      >Is medical cannabis right for you?</h5>
      <p>
       If you could benefit from medical cannabis and don't have a card yet,
       it's time to learn if medical cannabis is right for you. See if you have
       a condition that qualifies you for a cannabis card in your
       state
       {' '}
       <a
        href="/education/getting-started#bookmark01"
        style={{
         fontWeight: 600,
         textDecoration: 'underline',
        }}
       >
        here
       </a>.
      </p>
      <div className="membership_includes">
       <h6>
        Bennabis Health Premium Membership:
       </h6>
       <ul>
        <li>
         <strong>
          A 15% discount on your medical cannabis at our{' '}
          <Link to="network-dispensary" className="hyperlink">
           Network Dispensaries
          </Link>
          , all day, every day!
         </strong>{' '}
         <i>*Exclusions may apply.</i>
        </li>
        <li>Online medical cannabis resources.</li>
        <li>Medical cannabis card required to join.</li>
        <li>
         Available in DE, MD, MA, NJ, NM, NY and DC. Check back as we continue to add new states and dispensary
         locations.
        </li>
       </ul>
       <p>
        Enroll and pay online via credit card - Visa, Mastercard, American Express or Discover accepted.
       </p>
       <div
        style={{
         marginLeft: 0,
         display: 'inline-block',
         padding: '0.25rem 0.75rem',
         background: '#0A670A',
         borderRadius: '1rem',
         textDecoration: 'none',
        }}
       >
        <a
         href="/enroll/premium-plan"
         style={{
          color: '#fff',
         }}
        >
         ENROLL NOW
        </a>
       </div>
      </div>

      <blockquote className="bordered">
       <p className="blockquote_text">
        "I reached out to Bennabis Health because I had a NJ state card but had to let it expire because I couldn’t
        afford the medicine any longer. Bennabis Health referred me to a physician so I could reapply for my card; I
        then enrolled in Bennabis Health, it was super easy, and when I got my state card in the mail I went to a network dispensary and got my medicine at a discount that I would not have qualified for without my Bennabis Health membership! I feel so much better now that I got my medicine. I would never have been able to do this without Bennabis Health!"
       </p>
       <p className="blockquote_credit">Bennabis Health Member</p>
      </blockquote>

      <div className="page_footer">
       <div className="switch_faq">
        <Link to="/faqs"> Members FAQs</Link>
       </div>
       <div className="switch_contact">
        Additional Questions? <Link to="/contact">Please Contact Us Here</Link>
       </div>
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Members
