import React from 'react'
import { Helmet } from 'react-helmet'

function Affiliates() {
 return (
  <React.Fragment>
   <Helmet>
    <title>
     Affiliates | Bennabis Health: Medical Cannabis Discounts & Education
    </title>
    <meta
     name="description"
     content="View Bennabis Health affiliates and find even more discounts and savings on various cannabis related products and services."
    />
   </Helmet>
   <div className="sectionWrap affiliates">
    <img
     src={require('../assets/images/graphic-01.png')}
     alt=""
     className="backgroundSource"
    />
    <div className="sectionContainer">
     <div className="section nuggmd">
      <div className="graphic" style={
       {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 10px',
        backgroundColor: 'white',
       }
      }>
       <img src={require('../assets/images/affiliates/cmed.png')} alt="" width="75%" />
       <img src={require('../assets/images/affiliates/leafwell.png')} alt="" width="75%" />
      </div>
      <div className="sectionDetail">
       <span className="heading-01">MEDICAL CANNABIS EVALUATIONS</span>
       <span className="heading-02">
        Get your Medical Cannabis certification at a discount through our
        affiliates.
       </span>
       <p>
        <a href="/membership-card">
         <strong>Speak with a medical cannabis expert today!</strong>
        </a>
       </p>
      </div>
     </div>
     <div className="section nuggmd">
      <div className="sectionDetail">
       <span className="heading-01">CANNABIS NURSE HOTLINE</span>
       <span className="heading-02">
        Speak with a trained cannabis nurse for guidance.
       </span>
       <p>
        <a href="https://leaf411.org/">
         <strong>Schedule your appointment today!</strong>
        </a>
       </p>
       <p>
        Their mission is to provide education and directional support on the
        safe use of legal cannabis.
       </p>
      </div>
      <div className="graphic">
       <img src={require('../assets/images/graphic-leaf411.png')} alt="" />
      </div>
     </div>
     <div className="section ardent">
      <div className="graphic">
       <img src={require('../assets/images/graphic-03.png')} alt="" />
      </div>
      <div className="sectionDetail">
       <span className="discount">SAVE $30</span>
       <span className="heading">On an Ardent FX or Nova Decarboxylator</span>
       <span className="code">
        with the Bennabis Health Promo <strong>Code: BH30</strong>
       </span>
       <p>
        <strong>THE EASY BAKE OVEN FOR ADULTS</strong>
        Dosed, flavored, served, and consumed your way. <br /> Save money, time
        and hassle with Ardent technology!
       </p>
       <div className="actionbutton">
        <a
         rel="noreferrer"
         target="_blank"
         href="https://ardentcannabis.com?afmc=BH30&utm_campaign=BH30&utm_source=leaddyno&utm_medium=affiliate"
        >
         <i className="fa fa-angle-right" aria-hidden="true"></i> Click Here
        </a>
       </div>
      </div>
     </div>
     <div className="section nuggmd">
      <div className="sectionDetail">
       <span className="heading-01">SMART CANNABIS DEVICE</span>
       <span className="heading-02">
        Get your medical cannabis vape device at a discount.
       </span>
       <span className="text-01">Compatible with any 510 cartridge</span>
       <p>
        <strong>Enjoy the ideal cannabis experience, every time!</strong>
        Discount offered through MODE.
       </p>
       <p>Buy yours today.</p>
       <div className="actionbutton">
        <a rel="noreferrer" target="_blank" href="https://mode.co/">
         <i className="fa fa-angle-right" aria-hidden="true"></i> Click Here
        </a>
       </div>
      </div>
      <div className="graphic">
       <img src={require('../assets/images/graphic-mode-device.png')} alt="" />
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Affiliates
