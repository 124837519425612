import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import stateData from './json/states.json'
import './map.css'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { debounce, DialogContent } from '@mui/material'
import { medicalCannabisStates } from './medicalCannabisStates'

const mapRatio = 0.5
const margin = { top: 10, bottom: 10, left: 10, right: 10 }

const NetworkDispensaryMap = ({ listDispensary }) => {
 const mapRef = useRef(null)
 const [selectedStateName, setSelectedStateName] = useState(null)
 const [openDialog, setOpenDialog] = useState(false)
 const [mapSize, setMapSize] = useState({ width: 0, height: 0 })
 const [dispensaryFilter, setDispensaryFilter] = useState(null)

 const getFillColor = (stateName) => {
  let fillColor

  const isDispensaryState = listDispensary?.data.some(
   (item) => item.state === stateName,
  )

  const isMedicalCannabis = medicalCannabisStates.includes(stateName)

  if (isDispensaryState) {
   fillColor = '#0A670A'
  } else if (isMedicalCannabis) {
   fillColor = '#E2F0D9'
  } else {
   fillColor = '#FAFAFA'
  }

  return {
   fillColor: fillColor,
   hoverEnabled: isDispensaryState,
  }
 }

 const handleStateClick = (event, feature) => {
  if (listDispensary) {
   const copyArr = [...listDispensary?.data]
   const filterData = copyArr.filter(
    (item) => item.state === feature.properties.NAME,
   )
   setSelectedStateName(feature.properties.NAME)
   setDispensaryFilter(filterData)

   if (!!filterData.length) {
    setOpenDialog(true)
   }
  }
 }

 const updateMapSize = debounce(() => {
  if (mapRef.current) {
   const containerWidth = mapRef.current.getBoundingClientRect().width
   const width = containerWidth - margin.left - margin.right
   const height = width * mapRatio
   setMapSize({ width, height })
  }
 }, 100)

 useEffect(() => {
  updateMapSize()
  window.addEventListener('resize', updateMapSize)

  return () => {
   window.removeEventListener('resize', updateMapSize)
  }
 }, [])

 useEffect(() => {
  const currentRef = mapRef.current
  if (!currentRef || mapSize.width === 0) return

  d3.select(currentRef).selectAll('svg').remove()

  const { width, height } = mapSize

  const projection = d3
   .geoAlbersUsa()
   .translate([width / 2, height / 2])
   .scale(width)
  const pathGenerator = d3.geoPath().projection(projection)

  const svg = d3
   .select(mapRef.current)
   .append('svg')
   .attr('class', 'center-container')
   .attr('height', height + 70)
   .attr('width', `100%`)

  const scaleX = 0.1
  const scaleY = 0.3

  // Calculate scaled dimensions
  const scaledWidth = width * scaleX
  const scaledHeight = height * scaleY

  const LakeMichiganCoordinate = projection([-88.589746, 41.983764])

  svg
   .append('rect')
   .attr('x', LakeMichiganCoordinate[0] - scaledWidth / 2)
   .attr('y', LakeMichiganCoordinate[1] - scaledHeight / 2)
   .attr('width', scaledWidth)
   .attr('height', scaledHeight)
   .style('fill', '#D1E5F4')

  const legend = svg
   .append('g')
   .attr('class', 'legend')
   .attr('transform', `translate(${width - 200},${height})`)

  const bennabisHealthLegend = legend.append('g')

  bennabisHealthLegend
   .append('rect')
   .attr('width', 25)
   .attr('height', 10)
   .attr('fill', '#0A670A')

  bennabisHealthLegend
   .append('text')
   .attr('x', 30)
   .attr('y', 10)
   .text('Bennabis Health Network')

  const medicalCannabisLegend = legend
   .append('g')
   .attr('transform', 'translate(0, 20)')

  medicalCannabisLegend
   .append('rect')
   .attr('width', 25)
   .attr('height', 10)
   .attr('fill', '#E2F0D9')

  medicalCannabisLegend
   .append('text')
   .attr('x', 30)
   .attr('y', 10)
   .text('Medical Cannabis states')

  svg
   .append('rect')
   .attr('class', 'background center-container')
   .attr('height', height + margin.top + margin.bottom)
   .attr('width', '100%')

  const g = svg
   .append('g')
   .attr('class', 'center-container center-items us-state')
   .attr('transform', `translate(${margin.left},${margin.top})`)

  g.append('g')
   .attr('id', 'states')
   .selectAll('path')
   .data(stateData.features)
   .enter()
   .append('path')
   .attr('key', (feature) => feature.properties.NAME)
   .attr('d', pathGenerator)
   .attr('class', 'state')
   .attr('fill', (feature) => getFillColor(feature.properties.NAME).fillColor)
   .on('click', handleStateClick)
   .classed(
    'state',
    (feature) => getFillColor(feature.properties.NAME).hoverEnabled,
   )
   .attr('stroke', (feature) => '#9a9a9a')
   .attr('stroke-width', (feature) => 1)
   .each(function (feature) {
    d3.select(this).append('title').text(feature.properties.NAME)
   })

  stateData.features.forEach((feature) => {
   if (feature.properties.NAME === 'District of Columbia') {
    const centroid = projection([-76.53637, 38.59511])
    const circle = svg
     .append('circle')
     .attr('cx', centroid[0])
     .attr('cy', centroid[1])
     .attr('r', 8)
     .attr('class', 'state')
     .attr('fill', '#0A670A')
     .attr('stroke', '#9a9a9a')
     .attr('stroke-width', 2)
     .on('click', (event) => handleStateClick(event, feature))

    circle.append('title').text('District of Columbia')
   }
  })

  return () => {
   d3.select(currentRef).selectAll('svg').remove()
  }
 }, [mapSize])

 const handleCloseDialog = () => {
  setOpenDialog(false)
 }

 const editTitle = (state, title) => {
  if (state === 'District of Columbia') {
   return title
  }
  const findIndex = title.indexOf('-')
  return title.slice(findIndex + 1).trim()
 }

 return (
  <>
   <div className="sectionWrap">
    <h2 style={{ marginLeft: '20px', color: '#0A670A' }}>
     Find a participating dispensary in our network.
    </h2>
   </div>
   <div ref={mapRef} style={{ margin: '0 auto', width: '100%' }}></div>
   <p style={{ marginBottom: '0', marginLeft: '20px' }}>
    * Recreational/Adult-Use dispensary supporting medical patients, discounts
    may vary, and taxes apply.
   </p>
   <Dialog onClose={handleCloseDialog} open={openDialog}>
    <DialogTitle sx={{ textDecoration: 'underline' }}>
     {selectedStateName} Dispensaries:
    </DialogTitle>
    <DialogContent>
     <>
      {selectedStateName === 'New Jersey' && (
       <p style={{ fontSize: '12px' }}>
        * Recreational/Adult-Use dispensary supporting medical patients,
        discounts may vary, and taxes apply.
       </p>
      )}
      <ul>
       {dispensaryFilter?.map((state) => (
        <a key={state.id} href={state.link} target="_blank" rel="noreferrer">
         <li style={{ marginBottom: '1rem' }}>
          {editTitle(state.state, state.title)}
         </li>
        </a>
       ))}
      </ul>
     </>
    </DialogContent>
   </Dialog>
  </>
 )
}
export default NetworkDispensaryMap
