import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

const Header = () => {
 const [isMenuOpen, setIsMenuOpen] = useState(false)
 return (
  <React.Fragment>
   <div className="headerWrap" id="header">
    <div className="header">
     <div className="logo">
      {' '}
      <Link to="/">
       <img src={require('../assets/images/logo.svg').default} alt="" />
      </Link>
     </div>
     <div className="navIcon">
      {' '}
      {!isMenuOpen ? (
       <button onClick={() => setIsMenuOpen(true)}>
        <i className="fa fa-bars" aria-hidden="true"></i>
       </button>
      ) : (
       <button onClick={() => setIsMenuOpen(false)}>
        <i className="fa fa-times" aria-hidden="true"></i>
       </button>
      )}{' '}
     </div>
     <div className="sign-in">
      <a
       href={process.env.REACT_APP_HEALTH_PLAN_ENROLL_CONFIRM}
       target="_blank"
       rel="noreferrer"
      >
       <i className="fa fa-user-circle-o" aria-hidden="true"></i>
       Group Benefits
      </a>
     </div>
     <div className="sign-in">
      <Link to="/enroll">
       <i className="fa fa-user-circle-o" aria-hidden="true"></i>
       Individual Benefits
      </Link>
     </div>
     <div className="sign-in">
      <Link to="/contact">
       <i className="fa fa-phone" aria-hidden="true"></i>
       Contact Us
      </Link>
     </div>
     <div className="sign-in">
      <a
       rel="noreferrer"
       href={process.env.REACT_APP_MEMBERS_APP}
       target="_blank"
      >
       Sign In
      </a>
     </div>
    </div>
   </div>
   {isMenuOpen && (
    <React.Fragment>
     <div className="HamburgerMenu">
      <div className="menuContainer">
       <div className="menuTitle">MENU</div>
       <div className="navigation">
        <ul>
         <li>
          <NavLink onClick={() => setIsMenuOpen(false)} to="/members">
           Member Benefits
          </NavLink>
          <ul>
           <li>
            <NavLink onClick={() => setIsMenuOpen(false)} to="/members">
            Benefits of Joining
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/network-dispensary"
            >
             Dispensary Network
            </NavLink>
           </li>
           <li>
            <NavLink onClick={() => setIsMenuOpen(false)} to="/membership-card">
             Medical Certifications
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/patient-questionnaire"
            >
             Patient Questionnaire
            </NavLink>
           </li>
           <li>
            <NavLink onClick={() => setIsMenuOpen(false)} to="/promos">
             PROMOS or SPECIALS
            </NavLink>
           </li>
           <li>
            <a
             onClick={() => setIsMenuOpen(false)}
             rel="noreferrer"
             href="https://member.bennabishealth.com/"
             target="_blank"
            >
             Member Login
            </a>
           </li>
          </ul>
         </li>
         <li>
          <NavLink onClick={() => setIsMenuOpen(false)} to="/education">
           Cannabis Education
          </NavLink>
          <ul>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/education/getting-started"
            >
             Getting Started
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/education/curious"
            >
             Curious
            </NavLink>
           </li>
           <li>
            <NavLink onClick={() => setIsMenuOpen(false)} to="/education/ready">
             Ready
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/education/experienced"
            >
             Experienced
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/education/condition-specific"
            >
             Condition Specific
            </NavLink>
           </li>
          </ul>
         </li>
         <li>
          <NavLink
           onClick={() => setIsMenuOpen(false)}
           to="/workers-compensation"
          >
           Workers' Compensation
          </NavLink>
         </li>
         <li>
          <NavLink onClick={() => setIsMenuOpen(false)} to="/work-with-us">
           Partner with Us
          </NavLink>
          <ul>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/work-with-us/dispensaries"
            >
             Dispensaries
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/work-with-us/benefit-professionals"
            >
             Benefit Professionals
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/work-with-us/health-benefit-plans"
            >
             Health Benefit Plans
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/work-with-us/medical-providers"
            >
             Medical Providers
            </NavLink>
           </li>
          </ul>
         </li>
         <li>
          <NavLink onClick={() => setIsMenuOpen(false)} to="/about-us">
           About Us
          </NavLink>
          <ul>
           <li>
            <NavLink onClick={() => setIsMenuOpen(false)} to="/about-us">
             Introduction
            </NavLink>
           </li>
           <li>
            <NavLink
             onClick={() => setIsMenuOpen(false)}
             to="/media/bennabis-health-news"
            >
             Bennabis Health News
            </NavLink>
           </li>
           {/*<li><NavLink onClick={() => setIsMenuOpen(false)} to="/media/industry-news">Industry News</NavLink></li>*/}
           <li>
            <NavLink onClick={() => setIsMenuOpen(false)} to="/press-releases">
             Press Releases
            </NavLink>
           </li>
          </ul>
         </li>
         {/*<li><NavLink onClick={() => setIsMenuOpen(false)} to='/testimonials'>Testimonials</NavLink></li>*/}
         <li>
          <NavLink onClick={() => setIsMenuOpen(false)} to="/podcasts">
           Podcasts
          </NavLink>
         </li>
         <li>
          <NavLink onClick={() => setIsMenuOpen(false)} to="/affiliates">
           Affiliates
          </NavLink>
         </li>
         <li>
          <NavLink onClick={() => setIsMenuOpen(false)} to="/blog">
           Blog
          </NavLink>
         </li>
        </ul>
       </div>
      </div>
     </div>
    </React.Fragment>
   )}
  </React.Fragment>
 )
}

export default Header
